import React from "react";
import { socialLinks } from "../links";

const Footer = () => {
  return (
    <div className="relative bg-black  px-12 ">
      <div className="container flex flex-col justify-center gap-1 mx-auto items-center  h-24 ">
        <div className="flex justify-between lg:justify-around w-full items-center">
          <img src="/home_page/logo.png" alt="footer logo" className="h-5 sm:h-10 md:h-max" />
          <a href="https://masterbrews.com" target="_blank" rel="noreferrer">
            <img src="/home_page/powered.png" alt="powered" className="hidden md:block h-12 md:h-10 lg:h-full" />
          </a>
          <div className="gap-6 xl:gap-8 items-center flex justify-between" >
            {socialLinks.map((link, idx) => (
              <a href={link.url} key={idx} target="_blank" rel="noreferrer">
                <img src={link.icon} alt="social link" className="w-6 h-6 sm:w-8 sm:h-8 xl:w-10 xl:h-10" />
              </a>
            ))}
            <a href="https://raritysniper.com/nft-drops-calendar" target="_blank" rel="noreferrer">
              <img src="/icons/ic_nft_drop.svg" alt="social link" className="w-6 h-6 sm:w-8 sm:h-8 xl:w-10 xl:h-10" />
            </a>
          </div>
        </div>
        <img src="/home_page/powered.png" alt="powered" className="block md:hidden sm:h-12 " />
      </div>
    </div>
  );
};

export default Footer;
