export const menus = [
  {
    name: "home",
    path: "/",
    target: "",
    type: "external",
  },
  {
    name: "prizes",
    path: "/prizes",
    target: "",
    type: "external",
  },
//  {
//    name: "mint",
//    path: "/mint",
//    target: "",
//    type: "external",
//  },
//  {
//    name: "stake",
//    path: "/stake",
//    target: "",
//    type: "external",
//  },
//  {
//    name: "status",
//    path: "/status",
//    target: "",
//    type: "external",
//  },
//  {
//    name: "matchUp",
//    path: "/matchup",
//    target: "",
//    type: "external",
//  },
  {
    name: "seasons",
    path: "/seasons",
    target: "",
    type: "external",
  }
];
