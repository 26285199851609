import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "./navbar";
import Footer from "./footer";
import "@styles/global.scss";

const Layout = ({
  title = "End of Days | Ragnarok",
  children,
  contentClassName = "",
  visibleClass = true,
  page = "home"
}) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="relative flex flex-col font-brookline">
        <main
          className={`max-w-full flex-grow flex flex-col${contentClassName} ${visibleClass ? "" : "pb-0"
            } `}
        >
          <div className="relative">
            <Navbar page={page} />
            {children}
            <Footer />
          </div>
        </main>
      </div>
    </React.Fragment>
  );
};

export default Layout;
