export const socialLinks = [
  {
    url:"https://discord.gg/endofdays",
    icon:"/icons/ic_discord.svg"
  },
  {
    url:"https://twitter.com/endofdays_nft",
    icon:"/icons/ic_twitter.svg"
  },
  {
    url:"https://www.instagram.com/endofdaysnft/",
    icon:"/icons/ic_instagram.svg"
  },
  // {
  //   url:"#",
  //   icon:"/icons/ic_opensea.svg"
  // }
]
