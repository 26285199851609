import React, { useState, useEffect } from "react"
import { Link as ScrollLink } from "react-scroll"
import { Snackbar } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import { connect } from "react-redux"
import { ChainID, getWeb3, getOnBoard } from "@util/wallet"
import { setWeb3, setOnBoard, setWalletAddress, setConnected } from "@state/actions"
import { menus } from "../menu"
import "./Navbar.scss"
import { Link } from "gatsby"

const Navbar = ({
  page,
  web3,
  onBoard,
  walletAddress,
  connected,
  setWeb3,
  setOnBoard,
  setConnected,
  setWalletAddress
}) => {
  const [open, setOpen] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  })

  useEffect(() => {
    setWeb3(window.__web3 || null);
    setOnBoard(window.__onBoard || null);
    setWalletAddress(window.__walletAddress || null);
    setConnected(window.__connected || false);
  }, []);

  useEffect(() => {
    window.__web3 = web3;
  }, [web3]);
  useEffect(() => {
    window.__onBoard = onBoard;
  }, [onBoard]);
  useEffect(() => {
    window.__walletAddress = walletAddress;
  }, [walletAddress]);
  useEffect(() => {
    window.__connected = connected;
  }, [connected]);

  useEffect(() => {
    const addressAvailable = () => {
      if (walletAddress) {
        return;
      }

      if (web3 && web3.currentProvider && web3.currentProvider.selectedAddress &&
        (web3.currentProvider.selectedAddress.length > 0)) {
        setWalletAddress(web3.currentProvider.selectedAddress);
      } else {
        setTimeout(addressAvailable, 100);
      }
    }

    if (web3) {
      addressAvailable();
    }
  }, [web3, walletAddress]);

  useEffect(() => {
    const walletInitialize = async () => {
      const _web3 = await getWeb3()
      const _onBoard = await getOnBoard()
      const _chainId = await _web3.eth.getChainId()
      const _address = await _web3.eth.getAccounts()

      setWeb3(_web3)
      setOnBoard(_onBoard)

      const connectStatus = localStorage.getItem('rg_connect')
      if (connectStatus === null || connectStatus === 'true') {
        if (_address[0] && _chainId === ChainID)
          setConnected(true)

        setWalletAddress(_address[0])
      }
    }

    if (typeof window !== "undefined") {
      if (window.ethereum) {
        window.ethereum.on('chainChanged', handleNetworkChange);
        window.ethereum.on('disconnect', logout);
        window.ethereum.on('accountsChanged', logout);
      }
    }

    walletInitialize()
  }, [])

  const connectHandler = async () => {
    if (onBoard !== null) {
      if (!(await onBoard.walletSelect())) {
        return;
      }
      setConnected(await onBoard.walletCheck())
      localStorage.setItem('rg_connect', true)
    }
  }

  const logout = () => {
    if (onBoard != null) {
      onBoard.walletReset();
    }
    setWalletAddress(null);
    setConnected(false)
    localStorage.setItem('rg_connect', false)
  }

  const handleNetworkChange = (networkId) => {
    logout();
    if (networkId !== '0x1') {
      displayNotify("warning", "You've just changed the Ethereum network! The app will not function properly if you selected the wrong network.")
    }
  }

  const sliceAddress = (val) => {
    return val.slice(0, 5) + '...' + val.slice(-4)
  }

  const handleConnectDisconnect = async () => {
    if (!connected) {
      await connectHandler();
    } else {
      logout();
    }
  }

  const displayNotify = (type, content) => {
    setAlertState({
      open: true,
      message: content,
      severity: type,
    })
  }

  return (
    <div className={`bg-app-orange-trans flex justify-start h-16 md:h-20 lg:h-24 md:rounded-b-big w-full md:w-10/12  absolute top-0 z-50 left-1/2 transform -translate-x-1/2 font-brookline items-center 2xl:container`}>
      <div className="hidden xl:flex items-center justify-between w-full px-10 md:px-5 lg:px-12">
        <Link to="/">
          <img src="/home_page/logo.png" alt="logo" className="h-10 lg:h-full" />
        </Link>
        <div className="flex items-center justify-between">
          <ul className="flex items-center justify-around md:gap-0 2xl:gap-10 text-white menu-links">
            {menus.map((dt) => (
              <li
                className="font-action-man-bold cursor-pointer font-recoleta-bold text-secondary hover:bg-primary rounded-lg py-2 px-1 md:px-0 lg:px-1 text-xl md:text-md xl:text-2xl 2xl:text-3xl"
                key={dt.name}
              >
                {dt.type === "internal" ? (
                  <ScrollLink
                    to={dt.path}
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                    className="px-2"
                  >
                    {dt.name}
                  </ScrollLink>
                ) : (
                  <Link
                    className={`no-underline px-2 ${dt.name === page ? "active" : ""}`}
                    to={dt.path}
                    target={dt.target}
                  >
                    {dt.name}
                  </Link>
                )}
              </li>
            ))}
            <li
              className=" font-action-man-bold cursor-pointer font-recoleta-bold text-secondary hover:bg-primary rounded-lg py-2 px-1 md:px-0 lg:px-1 text-xl xl:text-2xl 2xl:text-3xl"
            >
              <a href='https://mb.mypinata.cloud/ipfs/QmeJPc6gcqvWkepUjkwtQaEpumSiczhnNeCSW6N5dMGEFk' className="px-2" download>litepaper</a>
            </li>
            <li className="py-2 pl-3 text-xl">
              {
                connected && walletAddress ?
                  <div className="btn-connect flex items-center justify-between">
                    <span className="mr-2">{sliceAddress(walletAddress)}</span>
                    <span className="cursor-pointer" onClick={handleConnectDisconnect}>
                      <img src="/icon_close.svg" alt="close icon" className="w-4" />
                    </span>
                  </div>
                  :
                  <button
                    id="btn-wallet-connect"
                    className="btn-connect cursor-pointer"
                    onClick={handleConnectDisconnect}
                  >
                    <span className="text-secondary tracking-wider font-recoleta-bold">Connect</span>
                  </button>
              }
            </li>
          </ul>
        </div>
      </div>
      <div
        className={`justify-between absolute px-12 w-full z-10 xl:hidden text-white cursor-pointer text-2xl flex  items-center`}
      >
        <img src="/home_page/logo.png" alt="logo" />
        <button className="font-pwjn" onClick={() => setOpen(true)}>
          <img src="/icons/ic_menu.svg" alt="menu" />
        </button>
      </div>
      {open && (
        <div
          className={`absolute min-h-header h-screen md:h-auto md:pb-10 bg-app-blue-light top-0 z-10 bg-gradient-to-b from-app-orange-dark to-app-orange transition-opacity-100 transform duration-100 w-full`}
        >
          <div className="relative w-full h-full md:h-auto">
            <div className="justify-between px-4 w-full h-16 z-10 xl:hidden text-white cursor-pointer text-2xl flex  items-center">
              <Link to="/">
                <img src="/home_page/logo.png" alt="logo" />
              </Link>
              <button
                className="text-right cursor-pointer text-white"
                onClick={() => setOpen(false)}
              >
                <img src="/icons/ic_menu.svg" alt="menu" />
              </button>
            </div>
            <ul className="flex flex-col items-center justify-between lg:gap-12 xl:gap-20 m-auto mt-8">
              {menus.map((dt) => (
                <li
                  className="font-action-man-bold cursor-pointer font-recoleta-bold text-secondary hover:bg-primary rounded-lg py-2 px-3 text-white hover:text-app-green-dark text-2xl xl:text-3xl"
                  key={dt.name}
                >
                  {dt.type === "internal" ? (
                    <ScrollLink
                      to={dt.path}
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      {dt.name}
                    </ScrollLink>
                  ) : (
                    <Link
                      className="no-underline px-5"
                      href={dt.path}
                      target={dt.target}
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      {dt.name}
                    </Link>
                  )}
                </li>
              ))}
              <li
                className="font-action-man-bold cursor-pointer font-recoleta-bold text-secondary hover:bg-primary rounded-lg py-2 px-3 text-white hover:text-app-green-dark text-2xl xl:text-3xl"
              >
                <a href='https://mb.mypinata.cloud/ipfs/QmeJPc6gcqvWkepUjkwtQaEpumSiczhnNeCSW6N5dMGEFk' download>litepaper</a>
              </li>
              <li className="py-2 pl-3 text-xl">
                {
                  connected && walletAddress ?
                    <div className="btn-connect flex items-center justify-between">
                      <span className="mr-2">{sliceAddress(walletAddress)}</span>
                      <span className="cursor-pointer" onClick={handleConnectDisconnect}>
                        <img src="/icon_close.svg" alt="close icon" className="w-4" />
                      </span>
                    </div>
                    :
                    <button
                      className="btn-connect cursor-pointer"
                      onClick={handleConnectDisconnect}
                    >
                      <span className="text-secondary tracking-wider font-recoleta-bold">Connect</span>
                    </button>
                }
              </li>
            </ul>
          </div>
        </div>
      )}
      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

const stateProps = (state) => ({
  web3: state.web3,
  onBoard: state.onBoard,
  walletAddress: state.walletAddress,
  connected: state.connected
});

const dispatchProps = (dispatch) => ({
  setWeb3: (data) => dispatch(setWeb3(data)),
  setOnBoard: (data) => dispatch(setOnBoard(data)),
  setWalletAddress: (address) => dispatch(setWalletAddress(address)),
  setConnected: (status) => dispatch(setConnected(status))
})

export default connect(stateProps, dispatchProps)(Navbar);
